import { UserInfo } from '@anabras/lib'
import React, { useContext } from 'react'

export const UserContext = React.createContext<UserInfo | null>(null)

export function useUserInfo() {
  const userContext = useContext(UserContext)
  const isAdmin = userContext ? userContext.role === 'ROLE_ADMIN' : false

  return [userContext, isAdmin] as const
}
