import { NotificationListOutput, ProcessDetail, ProcessList, UserInfo } from '@anabras/lib'
import Axios from 'axios'

export async function findProcesses(page: number, agent = '', name = '', dateOfBirth = '', crcNumber = '', typeOfProcess = '', fileStatus = '', fastTrack = '') {
  const url = `/api/processes?page=${page}&agent=${encodeURIComponent(agent)}&name=${encodeURIComponent(name)}&dateOfBirth=${encodeURIComponent(
    dateOfBirth
  )}&crcNumber=${encodeURIComponent(crcNumber)}&typeOfProcess=${encodeURIComponent(typeOfProcess)}&fileStatus=${fileStatus}&fastTrack=${fastTrack}`

  const res = await Axios.get<ProcessList>(url)
  return res.data
}

export async function getProcess(id: string) {
  const url = `/api/processes/${id}`
  const res = await Axios.get<ProcessDetail>(url)
  return res.data
}

export async function saveProcess(process: ProcessDetail) {
  const url = `/api/processes/${process.clientID}`
  await Axios.patch<ProcessDetail>(url, process)
}

export async function addClientObservationToProcess(clientID: number, clientObservation: string) {
  const url = `/api/processes/${clientID}/clientObservations`
  await Axios.post<ProcessDetail>(url, { clientObservation: clientObservation })
}

export async function getUserInfo() {
  try {
    const res = await Axios.get('/api/user/info')
    const user = res.data as UserInfo | null
    if (!user || !user.username) throw new Error('INVALID USER')
    return user
  } catch (err) {
    console.error('ERROR: ', err)
    window.location.assign('/login')
    return null
  }
}

export async function getDefaultProcessFor(agent: string, typeOfProcess: string) {
  const url = `/api/agents/${agent}/defaults?typeOfProcess=${typeOfProcess}`
  const res = await Axios.get<Partial<ProcessDetail>>(url)
  return res.data
}

export async function createProcessFor(agent: String, process: Partial<ProcessDetail>) {
  try {
    const url = `/api/agents/${agent}/create`
    const res = await Axios.post<ProcessDetail>(url, process)
    return res.data
  } catch (error: any) {
    if (error.response && error.response.data.message) {
      throw new Error(error.response.data.message)
    }
    throw error
  }
}

export async function getRelatedProcessesFor(clientID: number) {
  const url = `/api/processes/${clientID}/related`
  const res = await Axios.get<{ processes: ProcessDetail[] }>(url)
  return res.data.processes
}

export async function getNotifications() {
  const url = `/api/notifications`
  const res = await Axios.get<NotificationListOutput>(url)
  return res.data.Items
}

export async function getNotificationsForProcess(clientID: number) {
  const url = `/api/notifications?clientID=${clientID}`
  const res = await Axios.get<NotificationListOutput>(url)
  return res.data.Items
}

export async function readNotification(id: string) {
  const url = `/api/notifications/${id}/read`
  await Axios.post<NotificationListOutput>(url)
  return await getNotifications()
}

export async function readNotificationsForProcess(clientID: number) {
  const processNotifications = await getNotificationsForProcess(clientID)
  if (processNotifications.length === 0) return

  await Promise.all(
    processNotifications.map(notification => {
      const url = `/api/notifications/${notification.ID}/read`
      return Axios.post<NotificationListOutput>(url)
    })
  )
}
