import { Link } from 'react-router-dom'
import { Button, Form } from 'semantic-ui-react'
import logo from './LogoAnaBrasLawyer.png'

export function Login() {
  const showError = window.location.search.indexOf('reason=invalid') !== -1
  return (
    <div className="h-full w-full flex flex-col items-center justify-center bg-dark-blue">
      <div className="w-96 m-auto text-white grid grid-rows-2 items-center">
        <img className="w-full" src={logo} alt="ana bras logo" />
        <Form size="large" method="POST" action="/api/login" className="text-center pt-2 w-full">
          <h1 className="text-5xl font-bold">LOGIN</h1>
          <p className="">Hello, let's get started</p>
          <Form.Input name="username" fluid icon="user" iconPosition="left" placeholder="Username" />
          <Form.Input name="password" fluid icon="lock" iconPosition="left" placeholder="Password" type="password" />
          {showError && <div className="text-red-400">Invalid credentials. Try again</div>}
          <Button type="submit" fluid size="large" content="LOGIN" />
          <Link className="block mt-4" to="/">
            &lt; Go Back
          </Link>
        </Form>
      </div>
    </div>
  )
}
