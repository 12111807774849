import { ProcessDetailFileStatus } from '@anabras/lib'
import React from 'react'
import { Label } from 'semantic-ui-react'

export function FileStatusLabel({ status }: { status: ProcessDetailFileStatus }) {
  switch (status) {
    case 'MISSING DOCUMENTS':
      return <Label horizontal color="orange" size="mini" content={status} />
    case 'WITH NOTES':
      return <Label horizontal color="blue" size="mini" content={status} />
    case 'COMPLETE':
      return <Label horizontal color="grey" size="mini" content={status} />
    case 'NOTIFICATION':
      return <Label horizontal color="red" size="mini" content={status} />
    case 'CONCLUDE':
      return <Label horizontal color="green" size="mini" content={status} />
    case 'SCHEDULED':
      return <Label horizontal color="yellow" size="mini" content={status} />

    default:
      return <Label horizontal size="mini" content={status} />
  }
}
