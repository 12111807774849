import { ProcessDetail } from '@anabras/lib'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Form, Icon, Search, SearchProps } from 'semantic-ui-react'
import { findProcesses } from '../../core/api'
import { URLBuilder } from '../../core/URLBuilder'
import { useUserInfo } from '../../core/UserContext'

type RelationClientProps = {
  label: string
  process: ProcessDetail
  relations: ProcessDetail[]
  onRelationsChanged: (processes: ProcessDetail[]) => any
}
export function RelationField({ label, process, relations, onRelationsChanged }: RelationClientProps) {
  const [loading, setLoading] = useState(false)
  const [processes, setProcesses] = useState([] as ProcessDetail[])
  const [results, setResults] = useState([] as Record<string, any>)

  const [searchVisible, setSearchVisible] = useState(false)
  const [selectedProcess, setSelectedProcess] = useState(null as ProcessDetail | null)

  function mapProcessToResult(process: ProcessDetail) {
    return {
      key: process.clientID,
      'data-clientid': process.clientID,
      value: process.clientID,
      title: process.name,
      description: `[${process.agent}] proc. office #${process.procOfficeNum}`
    }
  }

  function handleSearchChanged(_: any, data: SearchProps) {
    setLoading(true)
    findProcesses(1, process.agent, data.value).then(processes => {
      setProcesses(processes.Items)
      setResults(processes.Items.filter(p => p.clientID !== process.clientID).map(mapProcessToResult))
      setLoading(false)
    })
  }

  function handleResultSelected(_: any, data: SearchProps) {
    const result = data.result
    if (!result) return
    const clientID = parseInt(result['data-clientid'])
    const process = processes.find(p => p.clientID === clientID)
    if (!process) return
    setSelectedProcess(process)
  }

  const [, isAdmin] = useUserInfo()

  return (
    <Form.Field>
      <label>{label}</label>
      <div className="flex flex-col items-start px-2 justify-between" style={{ height: 38 }}>
        <div>
          {relations.map(relation => (
            <React.Fragment key={relation.clientID}>
              <span>
                <Link to={URLBuilder.toProcess(relation.clientID)}>{relation.name}</Link> (
                <small>
                  #{relation.procOfficeNum} - {relation.typeOfProcess}
                </small>
                )
              </span>
              {isAdmin && <Icon className="cursor-pointer" name="times" onClick={() => onRelationsChanged(relations.filter(r => r.clientID !== relation.clientID))} />}
            </React.Fragment>
          ))}
        </div>
        {isAdmin && (
          <div className="flex ">
            {searchVisible && (
              <Search size="mini" onSearchChange={handleSearchChanged} minCharacters={2} onResultSelect={handleResultSelected} loading={loading} results={results} />
            )}
            {isAdmin && !searchVisible && (
              <Button
                compact
                size="mini"
                content="ADD"
                onClick={() => {
                  setSelectedProcess(null)
                  setSearchVisible(true)
                }}
              />
            )}
            {isAdmin && searchVisible && (
              <Button.Group size="mini">
                <Button
                  compact
                  primary
                  size="mini"
                  content="OK"
                  onClick={() => {
                    if (selectedProcess) onRelationsChanged([...relations, selectedProcess])
                    setSearchVisible(false)
                  }}
                />
                <Button compact size="mini" content="Cancel" onClick={() => setSearchVisible(false)} />
              </Button.Group>
            )}
          </div>
        )}
      </div>
    </Form.Field>
  )
}
