import { ProcessDetail } from '@anabras/lib'
import { Form, FormTextAreaProps } from 'semantic-ui-react'
import { useUserInfo } from '../../core/UserContext'
import { cx } from '../../cx'

export type CustomFormTextAreaProps = FormTextAreaProps & {
  process: ProcessDetail
  field: keyof Pick<ProcessDetail, 'clientObservations' | 'note' | 'missingDocuments' | 'receivedDocuments'>
  highlightConclude?: boolean
  highlightNotification?: boolean
  highlightNotes?: boolean
  highlightMissingDocuments?: boolean
  patchProcess: any
  agentEditable?: boolean
}

export function FormTextArea({
  process,
  field,
  patchProcess,
  highlightConclude,
  highlightNotification,
  highlightNotes,
  highlightMissingDocuments,
  agentEditable,
  ...props
}: CustomFormTextAreaProps) {
  const [, isAdmin] = useUserInfo()
  return (
    <Form.TextArea
      value={process[field] || ''} //
      disabled={!agentEditable && (!isAdmin || props.disabled)}
      rows="10"
      onChange={evt => patchProcess({ [field]: evt.target.value })}
      className={cx({
        'bg-green-500': process.fileStatus === 'CONCLUDE' && highlightConclude,
        'bg-red-500': process.fileStatus === 'NOTIFICATION' && highlightNotification,
        'bg-blue-500': process.fileStatus === 'WITH NOTES' && highlightNotes,
        'bg-amber-500': process.fileStatus === 'MISSING DOCUMENTS' && highlightMissingDocuments
      })}
      {...props}
    />
  )
}
