export type ToQuery = {
  [any: string]: string | string[] | any
}

export type To = {
  pathname: string
  search: string
  link: string
}

export function to(pathname: string, search: ToQuery = {}): To {
  const queryString = Object.keys(search)
    .filter((key) => search[key] !== undefined)
    .map((key) => {
      const value = search[key]
      if (typeof value === 'string') return [`${key}=${encodeURIComponent(value)}`]
      if (Array.isArray(value)) return value.map((val) => `${key}=${encodeURIComponent(val)}`)
      return [`${key}=${encodeURIComponent(value)}`]
    })
    .flatMap((a) => a)
    .join('&')

  return {
    pathname,
    search: queryString,
    link: `${pathname}${queryString.length === 0 ? '' : '?'}${queryString}`,
  }
}
