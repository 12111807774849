import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Button, Form, Icon } from 'semantic-ui-react'
import { getAgents, getProcessTypes } from '../../configurations'
import { parseQueryString, URLBuilder } from '../../core/URLBuilder'
import { AddProcessModal } from './AddProcessModal'

export function SearchBar({ isAdmin }: { isAdmin: boolean }) {
  const location = useLocation()
  const query = parseQueryString(location.search)

  const [showAddProcessModal, setShowAddProcessModal] = useState(false)

  const [searchAgent, setSearchAgent] = useState(query.agent || '')
  const [searchByName, setSearchByName] = useState(query.name || '')
  const [searchByDateOfBirth, setSearchByDateOfBirth] = useState(query.dateOfBirth || '')
  const [searchByCRCNumber, setSearchByCRCNumber] = useState(query.crcNumber || '')
  const [searchByTypeOfProcess, setSearchByTypeOfProcess] = useState(query.typeOfProcess || '')
  const [searchByFastTrack, setSearchByFastTrack] = useState(query.fastTrack || '')
  const [fileStatus, setFileStatus] = useState(query.fileStatus || '')

  function handleClick(fileStatus: string) {
    const add = query.fileStatus !== fileStatus
    const newFileStatus = add ? fileStatus : ''
    setFileStatus(newFileStatus)
    handleSearchProcesses(newFileStatus)
  }

  function handleSearchProcesses(newFileStatus: string) {
    window.location.assign(
      URLBuilder.toProcesses({
        agent: searchAgent,
        name: searchByName,
        dateOfBirth: searchByDateOfBirth,
        crcNumber: searchByCRCNumber,
        typeOfProcess: searchByTypeOfProcess,
        fastTrack: searchByFastTrack,
        fileStatus: newFileStatus
      }).link
    )
  }

  function handleResetSearchProcesses() {
    window.location.assign(URLBuilder.toProcesses().link)
  }

  return (
    <div className="flex items-center">
      <AddProcessModal open={showAddProcessModal} onClose={() => setShowAddProcessModal(false)} />
      <Form className="w-full">
        <Form.Group>
          <Form.Field width="10">
            <label>Filter by Status:</label>
            <Button.Group>
              <Button basic={fileStatus !== 'MISSING'} color="orange" content="MISSING DOCUMENTS" onClick={() => handleClick('MISSING')} />
              <Button basic={fileStatus !== 'WITH NOTES'} color="blue" content="WITH NOTES" onClick={() => handleClick('WITH NOTES')} />
              <Button basic={fileStatus !== 'COMPLETE'} color="grey" content="COMPLETE" onClick={() => handleClick('COMPLETE')} />
              <Button basic={fileStatus !== 'NOTIFICATION'} color="red" content="WITH NOTIFICATION" onClick={() => handleClick('NOTIFICATION')} />
              <Button basic={fileStatus !== 'CONCLUDE'} color="green" content="CONCLUDE" onClick={() => handleClick('CONCLUDE')} />
            </Button.Group>
          </Form.Field>
          <Form.Field width="2">
            <label>&nbsp;</label>
            <Button
              basic={fileStatus !== 'REMINDER'}
              color="yellow"
              content={
                <span className="whitespace-nowrap">
                  REMINDER <Icon name="info circle" title="1 month interval from today. Results are sorted in ascending date of reminder date" />
                </span>
              }
              onClick={() => handleClick('REMINDER')}
            />
          </Form.Field>
          <Form.Field width="4">
            <label>&nbsp;</label>
            <Button.Group>
              <Form.Button primary content="SEARCH" onClick={() => handleSearchProcesses(fileStatus)} />
              <Form.Button content="RESET" onClick={handleResetSearchProcesses} />
              {isAdmin && <Form.Button content="NEW" color="teal" onClick={() => setShowAddProcessModal(true)} />}
            </Button.Group>
          </Form.Field>
        </Form.Group>
        <Form.Group>
          {isAdmin && (
            <Form.Dropdown
              label="Agent:"
              options={getAgents()}
              clearable
              placeholder="Select Agent"
              value={searchAgent}
              onChange={(_, data) => setSearchAgent(data.value as string)}
              selection
            />
          )}
          <Form.Input label="Search By Name:" value={searchByName} onChange={evt => setSearchByName(evt.target.value)} icon="search" />
          <Form.Input label="Search By Date of Birth:" type="date" value={searchByDateOfBirth} onChange={evt => setSearchByDateOfBirth(evt.target.value)} />
          <Form.Input label="Search By CRC:" value={searchByCRCNumber} onChange={evt => setSearchByCRCNumber(evt.target.value)} icon="search" />
          <Form.Dropdown
            label="Search By Type of Process:"
            options={getProcessTypes()}
            clearable
            placeholder="Select Type of Process"
            value={searchByTypeOfProcess}
            onChange={(_, data) => setSearchByTypeOfProcess(data.value as string)}
            selection
          />
          <Form.Dropdown
            label="Fast Track"
            selection
            clearable
            options={[{ value: 'TRUE', text: 'Yes' }]}
            value={searchByFastTrack}
            onChange={(_, data) => setSearchByFastTrack(data.value as string)}
          />
        </Form.Group>
      </Form>
    </div>
  )
}
