import { ProcessDetail, ProcessDetailType } from '@anabras/lib'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Form, Message, Modal } from 'semantic-ui-react'
import { getAgents, getProcessTypes } from '../../configurations'
import { createProcessFor, getDefaultProcessFor } from '../../core/api'
import { URLBuilder } from '../../core/URLBuilder'

export function AddProcessModal({ open, onClose }: { open: boolean; onClose: () => any }) {
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(undefined as Error | undefined | any)
  const [typeOfProcess, setTypeOfProcess] = useState('ESCOLHA' as ProcessDetailType)
  const [agent, setAgent] = useState('')
  const [process, setProcess] = useState({} as Partial<ProcessDetail>)
  const [processCreated, setProcessCreated] = useState({} as Partial<ProcessDetail>)

  useEffect(() => {
    if (agent && typeOfProcess) {
      setProcess({})
      getDefaultProcessFor(agent, typeOfProcess).then(setProcess)
    }
  }, [agent, typeOfProcess])

  function canSubmit() {
    if (!agent || !typeOfProcess || typeOfProcess === 'ESCOLHA') return false
    if (process.typeOfProcess === 'ESCOLHA') return false
    if (process.name?.trim().length === 0) return false
    if (process.dateOfBirth?.trim().length === 0) return false

    return true
  }

  async function createProcess() {
    if (!process.agent) return

    try {
      setSuccess(false)
      setError(undefined)
      const newProcess = await createProcessFor(process.agent, process)
      setProcessCreated(newProcess)
      setSuccess(true)
      setProcess(await getDefaultProcessFor(newProcess.agent, typeOfProcess))
    } catch (err) {
      setError(err)
    }
  }

  function patchProcess(partial: Partial<ProcessDetail>) {
    const newProcess = Object.assign({}, process, partial)
    setProcess(newProcess)
    setSuccess(false)
    setError(false)
  }

  return (
    <Modal
      open={open}
      header="NEW PROCESS"
      size="large"
      content={
        <Modal.Content>
          <Form>
            <p>Please fill in the following information about the process:</p>
            <Form.Group>
              <Form.Dropdown
                label="TYPE"
                width="5"
                selection
                fluid
                options={getProcessTypes()}
                value={typeOfProcess}
                onChange={(e, data) => setTypeOfProcess(data.value as ProcessDetailType)}
              />
              <Form.Dropdown
                label="AGENT"
                width="4"
                search
                options={getAgents()}
                placeholder="Select Agent"
                value={process.agent}
                onChange={(_, data) => setAgent(data.value as string)}
                selection
              />
              {/* {process.agent && <Form.Input label="PROC Nr" type="number" loading={!!process.agent && !process.procOfficeNum} value={process.procOfficeNum} disabled />} */}
              {process.agent && <Form.Input width="4" required label="NAME" value={process.name || ''} onChange={evt => patchProcess({ name: evt.target.value })} />}
              {process.agent && (
                <Form.Input width="3" required type="date" label="DATE OF BIRTH" value={process.dateOfBirth} onChange={evt => patchProcess({ dateOfBirth: evt.target.value })} />
              )}
            </Form.Group>
          </Form>
          {success && (
            <Message success>
              <Message.Content>
                Process{' '}
                <Link to={URLBuilder.toProcess(processCreated.clientID || '')}>
                  <strong className="underline hover:no-underline">
                    {processCreated.agent}/{processCreated.procOfficeNum}
                  </strong>
                </Link>{' '}
                created with success.
              </Message.Content>
            </Message>
          )}
          {error && <Message error content={'Error creating the process: ' + error.message} />}

          <small>
            PROCESS TYPE GROUPS
            <table className="ui table very compact" style={{ width: 300 }}>
              <tr>
                <td>
                  <strong>A</strong>
                </td>
                <td>Art.6º n.º 7 (Jewish)</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <strong>B</strong>
                </td>
                <td>Art. 3º (Spouse)</td>
                <td>Art.2º (Minor)</td>
              </tr>
              <tr>
                <td>
                  <strong>C</strong>
                </td>
                <td>Art.1º (Minor)</td>
                <td>Marriage Transcription</td>
              </tr>
              <tr>
                <td>
                  <strong>D</strong>
                </td>
                <td>Before 61</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <strong>E</strong>
                </td>
                <td>After 61</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <strong>F</strong>
                </td>
                <td>Court</td>
                <td></td>
              </tr>
            </table>
          </small>
        </Modal.Content>
      }
      actions={
        <Modal.Actions>
          <output></output>
          <Button content="Cancel" onClick={onClose} />
          <Button content="Create" primary={canSubmit()} disabled={!canSubmit()} onClick={createProcess} />
        </Modal.Actions>
      }
    />
  )
}
