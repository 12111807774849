import { ProcessDetailFileStatus } from '@anabras/lib'

export function getAgents() {
  return [
    { value: 'MOTTY', text: 'MOTTY' }, // Note: This comment is here for having the agents listed vertically
    { value: 'MOSHE', text: 'MOSHE' },
    { value: 'LISBONLISS', text: 'LISBONLISS' },
    { value: 'RONI', text: 'RONI' },
    { value: 'ILAN', text: 'ILAN' },
    { value: 'SALI', text: 'SALI' },
    { value: 'JORGE', text: 'JORGE' },
    { value: 'AMITAI', text: 'AMITAI' },
    { value: 'YAIR', text: 'YAIR' },
    { value: 'MERON', text: 'MERON' },
    { value: 'SNIR', text: 'SNIR' },
    { value: 'MORAN', text: 'MORAN' },
    { value: 'NAOR', text: 'NAOR' },
    { value: 'REGAN', text: 'REGAN' },
    { value: 'PARTICULAR', text: 'PARTICULAR' },
    { value: 'TOM', text: 'TOM' },
    { value: 'GUY', text: 'GUY' },
    { value: 'YOHAI', text: 'YOHAI' },
    { value: 'URI', text: 'URI' },
    { value: 'VALERIA', text: 'VALERIA' },
    { value: 'ANDRES', text: 'ANDRES' }
  ]
}

export function getFileStatus(): { value: ProcessDetailFileStatus; text: string }[] {
  return [
    { value: '', text: '(ESCOLHA)' },
    { value: 'MISSING DOCUMENTS', text: 'MISSING DOCUMENTS' }, // Some documents are missing
    { value: 'WITH NOTES', text: 'WITH NOTES' }, // Process has notes worth reading
    { value: 'NOTIFICATION', text: 'NOTIFICATION' },
    { value: 'SCHEDULED', text: 'SCHEDULED' },
    { value: 'COMPLETE', text: 'COMPLETE' },
    { value: 'CONCLUDE', text: 'CONCLUDE' },
    { value: 'CLASSIFICATION', text: 'CLASSIFICATION' }
  ]
}

export function getProcessTypes() {
  return [
    { value: 'ESCOLHA', text: '(ESCOLHA)' },
    { value: 'JEWISH', text: 'Art.6º n.º 7 (Jewish)' },
    { value: 'ARTICLE 3', text: 'Art. 3º (Spouse)' },
    { value: 'MINOR ART 2', text: 'Art.2º (Minor)' },
    { value: 'MINOR ART 1', text: 'Art.1º (Minor)' },
    { value: 'MARRIAGE', text: 'Marriage Transcription' },
    { value: 'AFTER', text: 'After 61' },
    { value: 'BEFORE', text: 'Before 61' },
    { value: 'COURT', text: 'Court files' }
  ]
}
