import { ProcessDetail } from '@anabras/lib'
import Cleave from 'cleave.js/react'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { Link, useParams } from 'react-router-dom'
import { Button, DropdownProps, Form, Header, Icon, Label, Popup, Segment } from 'semantic-ui-react'
import { ClipboardIcon } from '../../components/ClipboardIcon'
import { getAgents, getFileStatus, getProcessTypes } from '../../configurations'
import { getProcess, readNotificationsForProcess, saveProcess } from '../../core/api'
import { URLBuilder } from '../../core/URLBuilder'
import { useUserInfo } from '../../core/UserContext'
import { cx } from '../../cx'
import { FileStatusLabel } from './FileStatusLabel'
import { FormInput } from './FormInput'
import { FormTextArea } from './FormTextArea'
import { RelatedProcessesFor } from './RelatedProcessesFor'
import { RelationField } from './RelationField'

export function ProcessDetailForAdminPage() {
  const { id } = useParams<{ id: string }>()
  const [dirty, setDirty] = useState(false)
  const [process, setProcess] = useState(null as ProcessDetail | null)

  const [, isAdmin] = useUserInfo()

  useEffect(() => {
    getProcess(id).then(setProcess)
  }, [id])

  function handleReset() {
    getProcess(id).then(setProcess)
    setDirty(false)
  }

  function handleSave() {
    if (!process) return
    saveProcess(process).then(() => {
      toast.success('Process saved with success')
      getProcess(id).then(setProcess)
      setDirty(false)
    })
  }
  function handleMarkAsRead() {
    if (!process) return
    readNotificationsForProcess(process.clientID).then(() => {
      toast.success('Success')
    })
  }

  function patchProcess(partial: Partial<ProcessDetail>) {
    const newProcess = Object.assign({}, process, partial)
    setProcess(newProcess)
    setDirty(true)
  }

  function inputPatchFor(field: keyof ProcessDetail) {
    return (evt: any) => {
      const value = evt.target.value
      return patchProcess({ [field]: value })
    }
  }

  function dropdownPatchFor(field: keyof ProcessDetail) {
    return (evt: any, data: DropdownProps) => {
      const value = data.value
      return patchProcess({ [field]: value })
    }
  }

  if (!process)
    return (
      <Segment placeholder loading>
        Loading process...
      </Segment>
    )

  return (
    <div className="pb-28">
      <Button disabled={!dirty} floated="right" primary content="SAVE" onClick={handleSave} />
      <Button disabled={!dirty} floated="right" secondary basic content="RESET" onClick={handleReset} />

      <Link to={URLBuilder.toProcesses()}>
        <Icon name="chevron left" /> Back to processes
      </Link>
      <Header size="medium">
        <Header.Content>
          [{process.agent}] Process from <strong className="font-extrabold text-dark-blue">{process.name}</strong>
          <ClipboardIcon data={process.name} /> <Label basic content={process.crcNum} detail={<Label.Detail content={<ClipboardIcon data={process.crcNum} />} />} />
          <FileStatusLabel status={process.fileStatus} />
        </Header.Content>
      </Header>

      <Form className="">
        <div
          className={cx('flex gap-4 border rounded-xl border-gray-500 p-4 mt-1 mb-6', {
            'bg-art7': process.typeOfProcess === 'JEWISH',
            'bg-art3': process.typeOfProcess === 'ARTICLE 3',
            'bg-art2': process.typeOfProcess === 'MINOR ART 2',
            'bg-art1': process.typeOfProcess === 'MINOR ART 1',
            'bg-mariage': process.typeOfProcess === 'MARRIAGE',
            'bg-court': process.typeOfProcess === 'COURT'
          })}
        >
          <div className="flex-grow">
            <Form.Group widths="equal">
              {isAdmin && <Form.Dropdown label="AGENT" selection fluid options={getAgents()} value={process.agent} onChange={dropdownPatchFor('agent')} />}
              <FormInput label="PROC OFFICE Nº" process={process} field="procOfficeNum" type="number" patchProcess={patchProcess} />
              <Form.Dropdown
                disabled={!isAdmin}
                label="TYPE OF PROCESS"
                selection
                fluid
                options={getProcessTypes()}
                value={process.typeOfProcess}
                onChange={dropdownPatchFor('typeOfProcess')}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <FormInput label="ENTRY OFFICE DATE" process={process} field="entryOfficeDate" type="date" patchProcess={patchProcess} />
              <FormInput label="CLIENT Nº" process={process} field="clientNum" patchProcess={patchProcess} />
              <FormInput label="REMINDER" process={process} field="scheduledContactDate" patchProcess={patchProcess} type="date" />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Checkbox
                disabled={!isAdmin}
                label="FAST TRACK"
                toggle
                checked={process.fastTrack === 'TRUE'}
                onChange={(_, data) => patchProcess({ fastTrack: data.checked ? 'TRUE' : 'FALSE' })}
              />
              <Form.Field />
              <Form.Field />
            </Form.Group>
          </div>
          <div className="flex text-center items-center justify-center p-4 text-3xl font-bold w-1/6">{getProcessTypes().find(pt => pt.value === process.typeOfProcess)?.text}</div>
        </div>
        <strong>CLIENT DETAILS</strong>
        <div className="border rounded-xl border-gray-500 p-4 mt-1 mb-6">
          <Form.Group widths="equal">
            <FormInput label="NAME" process={process} field="name" patchProcess={patchProcess} />
            <FormInput type="date" label="DATE OF BIRTH" process={process} field="dateOfBirth" patchProcess={patchProcess} />
            <FormInput label="PASSPORT Nº" process={process} field="passportNum" patchProcess={patchProcess} />
          </Form.Group>
          <Form.Group widths="equal">
            <RelationField
              label="PARENTS NAME"
              process={process}
              relations={process.parentClients}
              onRelationsChanged={processes => patchProcess({ parentClientIDs: processes.map(p => p.clientID), parentClients: processes })}
            />
            <RelationField
              label="SPOUSE NAME"
              process={process}
              relations={process.spouseClients}
              onRelationsChanged={processes => patchProcess({ spouseClientIDs: processes.map(p => p.clientID), spouseClients: processes })}
            />
            <RelationField
              label="MINOR NAME"
              process={process}
              relations={process.minorClients}
              onRelationsChanged={processes => patchProcess({ minorClientIDs: processes.map(p => p.clientID), minorClients: processes })}
            />
          </Form.Group>
          {isAdmin && <hr className="my-5" />}
          {isAdmin && (
            <Form.Group widths="16">
              <Form.Dropdown
                width={8}
                label="RELATED CLIENT NAMES"
                search
                multiple
                selection
                allowAdditions
                value={process.relatedClientNames}
                options={(process.relatedClientNames || []).map(name => ({ key: name, text: name, value: name }))}
                onChange={(_, data) => patchProcess({ relatedClientNames: data.value as string[] })}
              />
              <Form.Field>
                <label>
                  (INVERSE) RELATED CLIENT NAMES <Popup trigger={<i className="fa fa-info-circle" />}>Clients where this client is listed.</Popup>
                </label>
                <RelatedProcessesFor clientID={process.clientID} />
              </Form.Field>
            </Form.Group>
          )}
        </div>
        <strong>CRC DETAILS</strong>
        <div className="border rounded-xl border-gray-500 p-4 mt-1 mb-6">
          <Form.Group widths="equal">
            <FormInput label="CRC ENTRY DATE" process={process} field="crcEntryDate" type="date" patchProcess={patchProcess} />
            <FormInput label="CRC NUMBER" process={process} field="crcNum" patchProcess={patchProcess} />
            <Form.Field disabled={!isAdmin}>
              <label>CODE</label>
              <Cleave options={{ blocks: [4, 4, 4], delimiter: '-', numericOnly: true }} value={process.code} onChange={inputPatchFor('code')} />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <FormInput label="REGISTER" process={process} error={process.register.length > 0} field="register" patchProcess={patchProcess} />
            <FormInput label="STAGE" process={process} field="stage" patchProcess={patchProcess} />
            <Form.Field />
          </Form.Group>
        </div>
        <strong>FILE STATUS</strong> <FileStatusLabel status={process.fileStatus} />
        <div className="border rounded-xl border-gray-500 p-4 mt-1 mb-6">
          <Form.Group widths="equal">
            <Form.Dropdown disabled={!isAdmin} label="STATUS" selection fluid options={getFileStatus()} value={process.fileStatus} onChange={dropdownPatchFor('fileStatus')} />
            <FormInput label="BIRTH Nº" process={process} field="assentNum" highlightConclude patchProcess={patchProcess} />
            <FormInput type="date" label="DATE OF CONCLUSION" process={process} field="conclusionDate" highlightConclude patchProcess={patchProcess} />
          </Form.Group>
          <Form.Group widths="equal">
            <FormInput
              label="NOTIFICATION DATE"
              process={process}
              type="date"
              field="notificationDate"
              highlightNotification
              patchProcess={patchProcess}
              disabled={process.fileStatus !== 'NOTIFICATION'}
            />
            <FormInput
              label="DATE OF RESOLUTION"
              process={process}
              type="date"
              field="resolutionDate"
              highlightNotification
              patchProcess={patchProcess}
              disabled={process.fileStatus !== 'NOTIFICATION'}
            />
            <Form.Field />
          </Form.Group>
        </div>
        <div className="xborder xrounded-xl border-gray-500 p-4 mb-10">
          <Form.Group widths="equal">
            <FormTextArea label="RECEIVED DOCUMENTS" process={process} field="receivedDocuments" patchProcess={patchProcess} disabled={!isAdmin} />
            <FormTextArea
              label="MISSING DOCUMENTS / NOTIFICATIONS / NOTE"
              process={process}
              field="missingDocuments"
              highlightNotification
              highlightMissingDocuments
              highlightNotes
              patchProcess={patchProcess}
              disabled={!isAdmin || (process.fileStatus !== 'NOTIFICATION' && process.fileStatus !== 'MISSING DOCUMENTS')}
            />
            {isAdmin && <FormTextArea label="INTERNAL NOTES" process={process} field="note" patchProcess={patchProcess} disabled={!isAdmin} />}
          </Form.Group>
        </div>
        <strong>CLIENT OBSERVATIONS</strong>{' '}
        <Popup
          trigger={<Icon name="info circle" />}
          content="Here you can write information and notes about the client. Ex: If you already sent a document or if you are waiting"
        />
        <div className="border rounded-xl border-gray-500 p-4 mt-1 mb-6">
          <Form.Group widths="equal">
            <FormTextArea agentEditable label="" process={process} field="clientObservations" patchProcess={patchProcess} />
          </Form.Group>
        </div>
      </Form>

      <div className="flex justify-end">
        <Button content="MARK AS READ" onClick={handleMarkAsRead} />
        <Button disabled={!dirty} primary content="SAVE" onClick={handleSave} />
        <Button disabled={!dirty} secondary basic content="RESET" onClick={handleReset} />
      </div>
    </div>
  )
}
