import { ProcessDetail } from '@anabras/lib'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getProcessTypes } from '../../configurations'
import { getRelatedProcessesFor } from '../../core/api'
import { URLBuilder } from '../../core/URLBuilder'

export function RelatedProcessesFor({ clientID }: { clientID: number }) {
  const [relatedProcesses, setRelatedProcesses] = useState([] as ProcessDetail[])

  useEffect(() => {
    getRelatedProcessesFor(clientID).then(processes => setRelatedProcesses(processes))
  }, [clientID])

  return (
    <>
      {relatedProcesses.map(related => (
        <React.Fragment key={related.clientID}>
          <span>
            <Link to={URLBuilder.toProcess(related.clientID)}>{related.name}</Link> [{getProcessTypes().find(t => t.value === related.typeOfProcess)?.text}] (
            <small>#{related.procOfficeNum}</small>)
          </span>
        </React.Fragment>
      ))}
    </>
  )
}
