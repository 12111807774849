import { ProcessDetail } from '@anabras/lib'
import { Form, FormInputProps } from 'semantic-ui-react'
import { useUserInfo } from '../../core/UserContext'
import { cx } from '../../cx'

export type CustomFormInputProps = FormInputProps & {
  process: ProcessDetail
  field: keyof ProcessDetail
  highlightConclude?: boolean
  highlightNotification?: boolean
  highlightNotes?: boolean
  highlightMissingDocuments?: boolean
  patchProcess: any
}

export function FormInput({ process, field, patchProcess, highlightConclude, highlightNotification, highlightNotes, highlightMissingDocuments, ...props }: CustomFormInputProps) {
  const [, isAdmin] = useUserInfo()
  return (
    <Form.Input
      value={process[field] || ''} //
      disabled={!isAdmin || props.disabled}
      onChange={evt => patchProcess({ [field]: evt.target.value })}
      className={cx({
        'bg-green-500': process.fileStatus === 'CONCLUDE' && highlightConclude,
        'bg-red-500': props.error || (process.fileStatus === 'NOTIFICATION' && highlightNotification),
        'bg-blue-500': process.fileStatus === 'WITH NOTES' && highlightNotes,
        'bg-amber-500': process.fileStatus === 'MISSING DOCUMENTS' && highlightMissingDocuments
      })}
      {...props}
    />
  )
}
