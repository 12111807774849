import { UserInfo } from '@anabras/lib'
import { useEffect, useState } from 'react'
import { NavLink, Route, Switch } from 'react-router-dom'
import { getUserInfo } from './core/api'
import { URLBuilder } from './core/URLBuilder'
import { UserContext } from './core/UserContext'
import { NotificationsPage } from './pages/notifications/NotificationsPage'
import { ProcessDetailForAdminPage } from './pages/processes/ProcessDetailForAdminPage'
import { ProcessDetailForAgentPage } from './pages/processes/ProcessDetailForAgentPage'
import { ProcessesPage } from './pages/processes/ProcessesPage'

export function Application() {
  const [user, setUser] = useState(null as UserInfo | null)
  useEffect(() => {
    getUserInfo().then(setUser)
  }, [])

  useEffect(() => {
    const timer = setInterval(() => {
      getUserInfo().then(setUser)
    }, 2000)
    return () => clearInterval(timer)
  }, [])

  const isAdmin = user?.role === 'ROLE_ADMIN'
  const hasNotifications = user?.hasNotifications === true

  return (
    <div>
      <div className="w-full bg-dark-blue text-white h-10 px-4 flex items-center">
        <span>
          <strong>ANA BRÁS LAWYER</strong> Processes Management App
        </span>
        <span className="ml-auto flex gap-x-4">
          <NavLink to={URLBuilder.toProcesses()} className="hover:underline hover:text-white">
            Processes
          </NavLink>
          |
          <NavLink to={URLBuilder.toNotifications()} className="hover:underline hover:text-white">
            Notifications
            {hasNotifications && <i className="fa fa-circle text-red-400 relative -top-1" />}
          </NavLink>
          |
          <strong>
            <i className="fas fa-user" /> {user && user.username}
          </strong>
          {isAdmin && <span className="font-extrabold">(ADMIN)</span>}|
          <a href="/api/logout" className="hover:underline hover:text-white">
            LOGOUT
          </a>
        </span>
      </div>
      <div className="container max-w-8xl mx-auto xborder p-8">
        <UserContext.Provider value={user}>
          <Switch>
            <Route exact path={URLBuilder.toProcesses().pathname} component={ProcessesPage} />
            <Route exact path={URLBuilder.toNotifications().pathname} component={NotificationsPage} />
            <Route exact path={URLBuilder.toProcess(':id').pathname} component={ProcessDetailForAdminPage} />
            <Route exact path={URLBuilder.toProcessTabs(':id').pathname} component={ProcessDetailForAgentPage} />
          </Switch>
        </UserContext.Provider>
      </div>
    </div>
  )
}
