// @ts-nocheck
import { Toaster } from 'react-hot-toast'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { Application } from './Application'
import { Login } from './pages/login/Login'

function App() {
  return (
    <BrowserRouter>
      <Toaster />
      <Switch>
        {/* <Route exact path="/" component={Homepage} /> */}
        <Redirect exact path="/" to="/login" />
        <Route exact path="/login" component={Login} />
        <Route path="/app" component={Application} />
      </Switch>
    </BrowserRouter>
  )
}

export default App
