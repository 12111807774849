import { ProcessList } from '@anabras/lib'
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Icon, Pagination, Table } from 'semantic-ui-react'
import { findProcesses } from '../../core/api'
import { parseQueryString, URLBuilder } from '../../core/URLBuilder'
import { useUserInfo } from '../../core/UserContext'
import { formatDate } from '../../formatDate'
import { FileStatusLabel } from './FileStatusLabel'
import { SearchBar } from './SearchBar'

export function ProcessesPage() {
  const [page, setPage] = useState(1)
  const [processes, setProcesses] = useState(null as ProcessList | null)
  const location = useLocation()

  useEffect(() => {
    const query = parseQueryString(location.search)
    findProcesses(page, query.agent, query.name, query.dateOfBirth, query.crcNumber, query.typeOfProcess, query.fileStatus, query.fastTrack).then(setProcesses)
  }, [location, page])

  const [, isAdmin] = useUserInfo()

  return (
    <div>
      <SearchBar isAdmin={isAdmin} />
      {processes && (
        <div>
          <div className="flex align-middle">
            <div className="text-2xl">{`Displaying ${processes.Items.length} of ${processes.Meta.TotalCount}`} total processes</div>
            <div className="ml-auto">
              <Pagination totalPages={processes.Meta.TotalPages} activePage={processes.Meta.Page} onPageChange={(_, data) => setPage(data.activePage ? +data.activePage : 1)} />
            </div>
          </div>
          <Table compact="very" singleLine selectable unstackable>
            <Table.Header>
              <Table.Row>
                {isAdmin && <Table.HeaderCell>AGENT</Table.HeaderCell>}
                <Table.HeaderCell title="PROC OFFICE Nº">PROC Nr</Table.HeaderCell>
                <Table.HeaderCell>NAME</Table.HeaderCell>
                <Table.HeaderCell>DATE OF BIRTH</Table.HeaderCell>
                <Table.HeaderCell>CRC Number</Table.HeaderCell>
                <Table.HeaderCell>TYPE OF PROCESS</Table.HeaderCell>
                <Table.HeaderCell>FILE STATUS</Table.HeaderCell>
                <Table.HeaderCell>FAST TRACK</Table.HeaderCell>
                <Table.HeaderCell>NOTIFICATION</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {processes.Items.map(data => (
                <Table.Row key={data.clientID}>
                  {isAdmin && <Table.Cell>{data.agent}</Table.Cell>}
                  <Table.Cell>{data.procOfficeNum}</Table.Cell>
                  <Table.Cell>
                    <Link className="hover:underline" to={isAdmin ? URLBuilder.toProcess(data.clientID) : URLBuilder.toProcessTabs(data.clientID)}>
                      {data.name}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>{formatDate(data.dateOfBirth)}</Table.Cell>
                  <Table.Cell>{data.crcNum}</Table.Cell>
                  <Table.Cell>{data.typeOfProcess}</Table.Cell>
                  <Table.Cell>
                    <FileStatusLabel status={data.fileStatus} />
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <Icon name="circle" color={data.fastTrack === 'TRUE' ? 'green' : 'grey'} />
                  </Table.Cell>
                  <Table.Cell>{formatDate(data.scheduledContactDate)}</Table.Cell>
                  <Table.Cell>
                    <Link to={isAdmin ? URLBuilder.toProcess(data.clientID) : URLBuilder.toProcessTabs(data.clientID)}>DETAILS</Link>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      )}
    </div>
  )
}
