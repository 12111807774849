import ClipboardJS from 'clipboard'
import React, { useState } from 'react'
import { Icon, Popup } from 'semantic-ui-react'

// Initialized the clipboard
new ClipboardJS('.clipboard-icon-cups') // eslint-disable-line

export function ClipboardIcon({ data, text = 'Copy' }: { data: string; text?: string }) {
  const [popover, setPopover] = useState('Copy')

  return (
    <span>
      <Popup
        onClose={() => setPopover('Copy')}
        trigger={
          <span className="clipboard-icon-cups" onClick={() => setPopover('Copied!')} data-clipboard-action="copy" data-clipboard-text={data}>
            <Icon name="copy outline" size="small" />
          </span>
        }
        content={popover}
        size="small"
        style={{ zIndex: 9999999999 }}
        inverted
      />
    </span>
  )
}
