import { NotificationOutput, UserInfo } from '@anabras/lib'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Label, Table } from 'semantic-ui-react'
import { getNotifications, getUserInfo, readNotification } from '../../core/api'
import { URLBuilder } from '../../core/URLBuilder'
import { cx } from '../../cx'
import { formatDate } from '../../formatDate'
import { FileStatusLabel } from '../processes/FileStatusLabel'

export function NotificationsPage() {
  const [user, setUser] = useState(null as UserInfo | null)
  useEffect(() => {
    getUserInfo().then(setUser)
  }, [])

  const [notifications, setNotifications] = useState([] as NotificationOutput[])

  useEffect(() => {
    getNotifications().then(setNotifications)
  }, [])

  function handleRefresh() {
    getNotifications().then(setNotifications)
  }

  function handleMarkAsRead(id: string) {
    readNotification(id).then(setNotifications)
  }

  return (
    <div>
      <h1 className="text-2xl uppercase font-extrabold flex justify-between">
        Notifications
        <Button content="REFRESH" icon="refresh" onClick={handleRefresh} />
      </h1>

      <Table compact="very" singleLine selectable unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell collapsing></Table.HeaderCell>
            <Table.HeaderCell>WHEN</Table.HeaderCell>
            {user?.role === 'ROLE_ADMIN' && <Table.HeaderCell>AGENT</Table.HeaderCell>}
            <Table.HeaderCell title="PROC OFFICE Nº">PROC Nr</Table.HeaderCell>
            <Table.HeaderCell>NAME</Table.HeaderCell>
            <Table.HeaderCell>TYPE</Table.HeaderCell>
            <Table.HeaderCell>STATUS</Table.HeaderCell>
            <Table.HeaderCell>WHAT</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell collapsing></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {notifications.map(notification => (
            <Table.Row key={notification.ID} warning={!notification.IsRead} className={cx({ 'opacity-70': notification.IsRead })}>
              <Table.Cell>
                <i
                  className={cx('fa fa-circle', {
                    'text-gray-400': notification.IsRead,
                    'text-red-400': !notification.IsRead
                  })}
                />
              </Table.Cell>
              <Table.Cell>{formatDate(notification.When)}</Table.Cell>
              {user?.role === 'ROLE_ADMIN' && <Table.Cell content={notification.Process.agent} />}
              <Table.Cell content={notification.Process.procOfficeNum} />
              <Table.Cell>
                <Link
                  className="hover:underline"
                  to={user?.role === 'ROLE_ADMIN' ? URLBuilder.toProcess(notification.Process.clientID) : URLBuilder.toProcessTabs(notification.Process.clientID)}
                >
                  {notification.Process.name}
                </Link>
              </Table.Cell>
              <Table.Cell content={notification.Process.typeOfProcess} />
              <Table.Cell>
                <FileStatusLabel status={notification.Process.fileStatus} />
              </Table.Cell>
              <Table.Cell>
                <Label.Group size="mini">
                  {notification.Tags.map(tag => (
                    <Label key={tag} content={tag}></Label>
                  ))}
                </Label.Group>
              </Table.Cell>
              <Table.Cell>
                {
                  <Link
                    className="hover:underline"
                    to={user?.role === 'ROLE_ADMIN' ? URLBuilder.toProcess(notification.Process.clientID) : URLBuilder.toProcessTabs(notification.Process.clientID)}
                  >
                    DETAILS
                  </Link>
                }
              </Table.Cell>
              <Table.Cell>{!notification.IsRead && <Button size="mini" secondary content="MARK AS READ" onClick={() => handleMarkAsRead(notification.ID)} />}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      {/* <pre className="debug m-10 p-4">{JSON.stringify(notifications, null, 2)}</pre> */}
    </div>
  )
}
