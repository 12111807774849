import { to } from './to'

export const URLBuilder = {
  toProcesses: (query: { [key: string]: string } = {}) => to('/app', query),
  toProcess: (id: number | string) => to(`/app/process/${id}`),
  toProcessTabs: (id: number | string) => to(`/app/process-tab/${id}`),
  toNotifications: (query: { [key: string]: string } = {}) => to('/app/notifications', query)
}

export function parseQueryString(search: string = '') {
  return search
    .replace('?', '')
    .split('&')
    .map(keyval => keyval.split('='))
    .reduce((acc, keyval) => ({ ...acc, [keyval[0]]: decodeURIComponent(keyval[1]).trim() }), {}) as { [key: string]: string }
}
